import { useMemo } from 'react';
import * as Library from '../library';
import deriveSnapshot from './derive/snapshot';

/**
 * @param {Object} snapshot
 * @return {Object}
 */
export default function useDerivedProps(snapshot) {
  const library = Library.useLibrary();
  return useMemo(() => {
    return deriveSnapshot({ library, snapshot });
  }, [library, JSON.stringify(snapshot)]);
}
