import { useRouter } from 'next/router';
import { useState, useCallback, memo } from 'react';
import { Button } from '@/atoms/buttons';
import { Size } from '@/atoms/enums';
import { useFilterContext } from '@/shared/providers';
import { Activities } from '@pkg/entities/library';
import shortuuid from '@pkg/uuid';
import { ActivityOwner } from '@/lib/enums';
import useDesignStore from '@/components/DesignContainer/hooks/useDesignStore';
import { useMessages } from '../context/MessagesContext';
import useCreatePrompt from '../hooks/useCreatePrompt';
import usePrompt from '../hooks/usePrompt';
import filtersPrompt from '../prompts/filters';
import intentPrompt from '../prompts/intent';
import mutationsPrompt from '../prompts/mutations';
import navigatePrompt from '../prompts/navigate';
import reportsPrompt from '../prompts/reports';
import { getReducers } from '../tools/config';
import createFilters from '../tools/createFilters/createFilters';
import createMutation from '../tools/createMutation/createMutation';
import generateReport from '../tools/generateReport/generateReport';
import navigateTo from '../tools/navigateTo/navigateTo';

export const ActionTypes = {
  generateReport: {
    label: 'Generate report',
    prompt: reportsPrompt,
    tools: { generateReport },
    callback: async (response) => {
      if (response.generateReport) {
        await response.generateReport.execute(response.generateReport);
      }
    },
  },
  createMutation: {
    label: 'Create changes',
    prompt: mutationsPrompt,
    tools: { createMutation },
    callback: async (response) => {
      if (response.createMutation) {
        await response.createMutation.execute(response.createMutation);
      }
    },
  },
  applyMutation: {
    label: 'Apply changes',
  },
  applyFilters: {
    label: 'Apply filters',
    hide: true,
  },
  navigateTo: {
    label: 'Navigate To',
    prompt: navigatePrompt,
    tools: { navigateTo },
    callback: async (response) => {
      if (response.navigateTo) {
        await response.navigateTo.execute(response.navigateTo);
      }
    },
  },
  createFilters: {
    label: 'Apply filters',
    prompt: filtersPrompt,
    tools: { createFilters },
    callback: async (response) => {
      if (response.createFilters) {
        await response.createFilters.execute(response.createFilters);
      }
    },
  },
};

const toolReducerMap = getReducers();

function convertNumbers(obj) {
  // Check if the current item is an object or an array
  if (typeof obj === 'object' && obj !== null) {
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        if (typeof obj[key] === 'object') {
          // Recursively call the function on nested objects or arrays
          convertNumbers(obj[key]);
        } else if (typeof obj[key] === 'string' && !isNaN(obj[key])) {
          // Convert strings that are numeric into actual numbers
          obj[key] = Number(obj[key]);
        }
      }
    }
  }
  return obj;
}

const Action = memo(function Action({ action }) {
  const { addMessage, getMessages, messages, addStream } = useMessages();
  const router = useRouter();
  const snapshot = useDesignStore((state) => state.snapshot);
  const mutate = useDesignStore((state) => state.actions.mutate);
  const [isExecuting, setIsExecuting] = useState(false);
  const actionType = ActionTypes[action?.type];
  const label = actionType?.label || 'Unknown';
  const { changeFilter } = useFilterContext();

  const { error, isLoading, data, prompt, stream } = usePrompt({
    tools: actionType?.tools,
  });

  const createPrompt = useCreatePrompt();

  const createLibraryActivities = Activities.mutations.useCreateMany();
  const getExisting = Activities.useStore((state) => state.getExisting);

  const addActivitiesToLibrary = useCallback(
    async (activities) => {
      if (!activities || !activities.length) return [];

      const createActivities = [];
      const mappedActivities = activities.map((activity) => {
        let library_uuid = getExisting(activity.description);
        if (!library_uuid) {
          library_uuid = shortuuid.generate();
          createActivities.push({
            uuid: library_uuid,
            description: activity.description,
          });
        }

        return {
          new_uuid: shortuuid.generate(),
          library_uuid,
          hours: activity.hours,
          owner_type: ActivityOwner.ROLE,
        };
      });

      if (createActivities.length) {
        await createLibraryActivities(createActivities);
      }

      return mappedActivities;
    },
    [createLibraryActivities, getExisting]
  );

  const handleAction = useCallback(async () => {
    if (isExecuting || isLoading || action.applied) {
      return;
    }

    // setIsExecuting(true);

    if (!actionType.prompt) {
      setIsExecuting(true);
      if (action.type === 'applyMutation') {
        const tools = action.parameters.toolResults;
        tools.forEach(async (tool) => {
          const toolName = tool.toolName;
          const payload = tool.result.payload;
          const total = tool.result.total;
          const activities = tool.result.activities;

          let suggestedActivities = null;
          if (activities) {
            suggestedActivities = await addActivitiesToLibrary(activities);
            payload.activities = suggestedActivities;
          }

          const reducer = toolReducerMap[toolName];
          const timesToApply = total || 1;
          for (let i = 0; i < timesToApply; i++) {
            const mutation = reducer(snapshot, payload);
            if (mutation) {
              try {
                // Apply mutation total number of times if total is set
                mutate(mutation, 'ai');
              } catch (error) {
                console.log({ error });
              }
            }
          }
        });
      }

      return;
    }

    try {
      const intentOptions = await createPrompt({
        basePrompt: intentPrompt,
        userText: action.parameters.userQuestion,
      });

      // addMessage({
      //   text: 'Figuring out intent.',
      //   sender: 'AI',
      //   parameters: { uuid: shortuuid.generate() },
      // });

      const intentResult = await prompt(intentOptions);

      const intent = intentResult.toolResults[0].result;

      const promptOptions = await createPrompt({
        intent,
        basePrompt: actionType.prompt,
        userText: action.parameters.userQuestion,
      });

      const response = await prompt(promptOptions);
      if (response && actionType.callback) {
        await actionType.callback(response);
        action.applied = true;
      }

      const data = response;

      if (!data) return;

      if (action.type === 'generateReport') {
        addStream({ action, stream: response });
        return;
      }

      if (action.type === 'createFilters') {
        let filters = data?.toolResults[0]?.args?.filters.conditions || {};

        filters = convertNumbers(filters);
        // convert filters which is an array of objects into an array of maps
        const newConditions = filters.map(
          (condition) => new Map(Object.entries(condition))
        );

        changeFilter({ groups: [{ conditions: newConditions }] });
        addMessage({
          text: `The following filters were applied`,
          sender: 'AI',
          action: 'applyFilters',
          parameters: {
            uuid: shortuuid.generate(),
            toolResults: data.toolResults,
          },
        });
      }

      if (action.type === 'navigateTo') {
        const url = data?.toolResults[0]?.args?.url || '';
        console.log('Begin navigating!!', url, data.text);
        window.location.href = url;
        // router.push(url);
      }

      if (action.type === 'createMutation') {
        if (data?.toolResults?.length > 0) {
          addMessage({
            text: `The following changes will  be applied`,
            action: 'applyMutation',
            parameters: {
              uuid: shortuuid.generate(),
              toolResults: data.toolResults,
            },
          });
        } else {
          const message = data?.choices[0]?.message?.content;
          addMessage({
            text: message,
            parameters: {},
            action: 'applyMutation',
          });
        }
      }
    } catch (err) {
      console.error('Error executing action:', err);
      addMessage({
        text: 'There was an error executing this action. Please try again.',
        sender: 'AI',
        parameters: { uuid: shortuuid.generate() },
        error: true,
      });
    } finally {
      setIsExecuting(false);
    }
  }, [action, actionType, createPrompt, isExecuting, isLoading, prompt]);

  if (actionType.hide) {
    return null;
  } else {
    return (
      <span style={{ color: 'green', marginLeft: '5px' }}>
        <Button
          color="secondary"
          label={isLoading ? 'Processing...' : label}
          onClick={handleAction}
          size={Size.SMALL}
          disabled={isExecuting || isLoading || action.applied || error}
        />
        {error && (
          <span style={{ color: 'red', marginLeft: '5px', fontSize: '0.8em' }}>
            Error: {error.message}
          </span>
        )}
      </span>
    );
  }
});

Action.displayName = 'Action';

export default Action;
