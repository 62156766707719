import env from '@beam-australia/react-env';
import ucfirst from '@pkg/utils/strings/ucfirst';

const PRODUCTION = ['staging', 'production'].includes(env('PLATFORM_ENV'));

/**
 * Primitive
 */

export const Action = Object.freeze({
  ADD: 'add',
  ARCHIVE: 'archive',
  BREAKDOWN: 'breakdown',
  CREATE: 'create',
  EDIT: 'edit',
  PUBLISH: 'publish',
  READ: 'read',
  REMOVE: 'remove',
  SHARE: 'share',
  SUMMARISE: 'summarise',
  PRIORITISE: 'prioritise',
});

export const ActivityOwner = Object.freeze({
  GROUP: 'GROUP',
  ROLE: 'ROLE',
});

export const AssigneeStatus = Object.freeze({
  ASSIGNED: 'ASSIGNED',
  UNASSIGNED: 'UNASSIGNED',
  PERSON: 'PERSON',
});

export const BeamibleInsight = Object.freeze({
  PRODUCTIVITY: 'PRODUCTIVITY',
  WORK_HEALTH: 'WORK_HEALTH',

  /**
   * @param {String} insight
   * @return {String}
   */
  label(insight) {
    return insight.split('_').join(' ').toLowerCase();
  },
});

export const BeamibleTag = Object.freeze({
  STRATEGIC_IMPORTANCE: 'BtMCuemqtmp4KGzaArqr1',
  SPECIALIST_CAPABILITY: 'BtMCuemqtmp4KGzaArqr2',
  ENERGISING: 'BtMCuemqtmp4KGzaArqr3',
  DEENERGISING: 'BtMCuemqtmp4KGzaArqr4',
  INTERNAL_MEETINGS: 'BtMCuemqtmp4KGzaArqr5',
  EXTERNAL_MEETINGS: 'BtMCuemqtmp4KGzaArqr6',
  ADMINISTRATIVE: 'BtMCuemqtmp4KGzaArqr7',
  EFFICIENCY_OPPORTUNITY: 'CBHQKzrwvcVHKdyd5sYtw',
  AUTOMATED: 'CBfTxVuVpdRTKoy9zJGRs',
  AUGMENTED: 'CBfU6MtgGCNEboFwZ1RDN',
  CRITICAL_WORK: 'CBHQ151cNafXumznkbDxj',
});

export const BulkOperators = Object.freeze({
  SET: 'SET',
  INCREASE: 'INCREASE',
  INCREASE_PERCENTAGE: 'INCREASE_PERCENTAGE',
  DECREASE: 'DECREASE',
  DECREASE_PERCENTAGE: 'DECREASE_PERCENTAGE',
});

export const CampaignStatus = Object.freeze({
  COMPLETE: 'COMPLETE',
  INCOMPLETE: 'INCOMPLETE',
  EXPIRED: 'EXPIRED',
  CLOSED: 'CLOSED',
  OPEN: 'OPEN',
  CANCELED: 'CANCELED',
});

export const ChipType = Object.freeze({
  ACTIVITY: 'ACTIVITY',
  HISTORICAL: 'HISTORICAL',
  GROUP: 'GROUP',
  LEAD: 'LEAD',
  LIVE: 'LIVE',
  MANAGER: 'MANAGER',
  ORGANISATION: 'ORGANISATION',
  ROLE: 'ROLE',
  PERSON: 'PERSON',
  SCENARIO: 'SCENARIO',
  SUPPORTER: 'SUPPORTER',

  /**
   * @param {ChipType} type
   * @returns {String}
   */
  label(type, plural) {
    switch (type) {
      case this.ACTIVITY:
        return plural ? 'ACTIVITIES' : 'ACTIVITY';
      case this.GROUP:
        return plural ? 'TEAMS' : 'TEAM';
      case this.LEAD:
        return plural ? 'TEAM LEADS' : 'TEAM LEADS';
      case this.MANAGER:
        return plural ? 'MANAGERS' : type;
      case this.PERSON:
        return plural ? 'PEOPLE' : type;
      case this.ROLE:
        return plural ? 'ROLES' : type;
      case this.SCENARIO:
        return plural ? 'SCENARIOS' : type;
      case this.SUPPORTER:
        return plural ? 'SUPPORTERS' : type;
      default:
        return type;
    }
  },
});

export const ClientError = Object.freeze({
  CANCELLED: 'CANCELLED',
  NOT_AUTHORIZED: 'NOT_AUTHORIZED',
  NOT_FOUND: 'NOT_FOUND',
  UNKNOWN: 'UNKNOWN',
});

export const Completeness = Object.freeze({
  NONE: 'NONE',
  PARTIAL: 'PARTIAL',
  FULL: 'FULL',
});

export const Comparator = Object.freeze({
  CONTAINS: 'CONTAINS',
  CONTAINS_ANY: 'CONTAINS_ANY',
  CONTAINS_ALL: 'CONTAINS_ALL',
  ENTITY_IS_ANY: 'ENTITY_IS_ANY',
  ENTITY_IS_ANY_NOT: 'ENTITY_IS_ANY_NOT',
  EQUALS: 'EQUALS',
  EXCLUDE_ANY: 'EXCLUDE_ANY',
  EXCLUDE_ALL: 'EXCLUDE_ALL',
  EXCLUDE_ANY_OBJECT: 'EXCLUDE_ANY_OBJECT',
  EXCLUDE_ALL_OBJECT: 'EXCLUDE_ALL_OBJECT',
  GREATER_THAN: 'GREATER_THAN',
  GREATER_THAN_OR_EQUAL: 'GREATER_THAN_OR_EQUAL',
  HAS_ITEM_ANY: 'HAS_ITEM_ANY',
  HAS_ITEM_ALL: 'HAS_ITEM_ALL',
  HAS_OBJECT_ANY: 'HAS_OBJECT_ANY',
  HAS_OBJECT_ALL: 'HAS_OBJECT_ALL',
  IS: 'IS',
  IS_NONE_OF: 'IS_NONE_OF',
  IS_ONE_OF: 'IS_ONE_OF',
  LESS_THAN: 'LESS_THAN',
  LESS_THAN_OR_EQUAL: 'LESS_THAN_OR_EQUAL',
  PERSON_IS: 'PERSON_IS',
  STARTS_WITH: 'STARTS_WITH',
});

export const ConnectionProvider = Object.freeze({
  GOOGLE: 'GOOGLE',
});

export const DesignAction = Object.freeze({
  NONE: 'NONE',
  CREATE: 'CREATE',
  UPDATE: 'UPDATE',
  REMOVE: 'REMOVE',

  /**
   * @param {String} action
   * @returns {DesignAction}
   */
  from(action) {
    return this[action.toUpperCase()];
  },
});

export const DesignEntity = Object.freeze({
  ACTIVITY: 'ACTIVITY',
  BRANCH: 'BRANCH',
  DESIGN: 'DESIGN',
  SCENARIO: 'SCENARIO',
  GROUP: 'GROUP',
  MANAGER: 'MANAGER',
  ORGANISATION: 'ORGANISATION',
  PERSON: 'PERSON',
  ROLE: 'ROLE',
  SKILL: 'SKILL',
  LIBRARY_ROLE: 'LIBRARY_ROLE',

  /**
   * @param {String} plural
   * @returns {DesignEntity}
   */
  fromPlural: function (plural) {
    switch (plural?.toLowerCase()) {
      case 'activities':
        return this.ACTIVITY;
      case 'designs':
        return this.DESIGN;
      case 'scenarios':
        return this.SCENARIO;
      case 'groups':
        return this.GROUP;
      case 'organisations':
        return this.ORGANISATION;
      case 'people':
        return this.PERSON;
      case 'roles':
        return this.ROLE;
      case 'libraryRoles':
        return this.LIBRARY_ROLE;
      default:
        return null;
    }
  },

  /**
   * @param {String} entity
   * @returns {DesignEntity}
   */
  toPlural: function (entity) {
    switch (entity?.toUpperCase()) {
      case this.ACTIVITY:
        return 'activities';
      case this.DESIGN:
        return 'designs';
      case this.SCENARIO:
        return 'scenarios';
      case this.GROUP:
        return 'groups';
      case this.ORGANISATION:
        return 'organisations';
      case this.PERSON:
        return 'people';
      case this.ROLE:
        return 'roles';
      case this.LIBRARY_ROLE:
        return 'libraryRoles';
      default:
        return null;
    }
  },

  fromScope: function (scope) {
    switch (scope) {
      case DesignScope.ORGANISATION:
        return this.ORGANISATION;
      case DesignScope.MANAGER:
        return this.ROLE;
      case DesignScope.GROUP:
        return this.GROUP;
      case DesignScope.ROLE:
        return this.ROLE;
      default:
        return null;
    }
  },
});

export const DesignOwner = Object.freeze({
  ORGANISATION: 'ORGANISATION',
  PERSON: 'PERSON',
});

export const DesignLevel = Object.freeze({
  GROUP: 'GROUP',
  INSIGHTS: 'INSIGHTS',
  LIBRARY_ROLES: 'LIBRARY_ROLES',
  MANAGER: 'MANAGER',
  ORGANISATION: 'ORGANISATION',
  PERSON: 'PERSON',
  ROLE: 'ROLE',
});

export const DesignPermission = Object.freeze({
  MANAGE: 'design.manage',
  READ: 'design.read',
  WRITE: 'design.write',
});

export const DesignRole = Object.freeze({
  ADMIN: 'design.admin',
  EDITOR: 'design.editor',
  VIEWER: 'design.viewer',
});

export const DesignScope = Object.freeze({
  ORGANISATION: 'ORGANISATION',
  MANAGER: 'MANAGER',
  GROUP: 'GROUP',
  ROLE: 'ROLE',

  /**
   * @param {DesignScope} scope
   * @param {Boolean} lower
   * @return {String}
   */
  label(scope, lower = false) {
    if (!scope) {
      return '';
    }

    const label = scope === this.GROUP ? 'Team' : ucfirst(scope.toLowerCase());
    return lower ? label.toLowerCase() : label;
  },

  /**
   * @param {String} [suffix]
   * @param {Boolea} [wildcard]
   * @returns {Object[]}
   */
  options(suffix = '', wildcard = false) {
    const options = [];

    if (wildcard) {
      options.push({ value: '*', label: `All${suffix}` });
    }

    ['ORGANISATION', 'MANAGER', 'GROUP', 'ROLE'].forEach((value) => {
      const label = value === 'GROUP' ? 'Team' : ucfirst(value, true);
      options.push({ value, label: `${label}${suffix}` });
    });

    return Object.freeze(options);
  },
});

export const DesignType = Object.freeze({
  LIVE: 'LIVE',
  SCENARIO: 'SCENARIO',
  SHARED_SCENARIO: 'SHARED_SCENARIO',
});

export const DiagramAction = Object.freeze({
  DROP: 'DROP',
});

export const DiagramEntity = Object.freeze({
  DESIGN_ACTIVITY: 'DESIGN_ACTIVITY',
  DESIGN_GROUP: 'DESIGN_GROUP',
  DESIGN_ROLE: 'DESIGN_ROLE',
  LIBRARY_ACTIVITY: 'LIBRARY_ACTIVITY',
  LIBRARY_PROPERTY: 'LIBRARY_PROPERTY',
  LIBRARY_PROPERTY_OPTION: 'LIBRARY_PROPERTY_OPTION',
  LIBRARY_ROLE: 'LIBRARY_ROLE',
  LIBRARY_SKILL: 'LIBRARY_SKILL',
  LIBRARY_TAG: 'LIBRARY_TAG',
  LIBRARY_TAG_CATEGORY: 'LIBRARY_TAG_CATEGORY',
  DESIGN_ACTIVITY_UNALLOCATED: 'DESIGN_ACTIVITY_UNALLOCATED',

  /**
   * @param {DiagramEntity} entity
   * @returns {String|null}
   */
  toDesignEntity(entity) {
    return DesignEntity[entity.replace('DESIGN_', '')] ?? null;
  },
});

export const DiagramExpandable = Object.freeze({
  BRANCH: 'BRANCH',
  CARD: 'CARD',
  GRAPH: 'GRAPH',
  GROUP: 'GROUP',
  ITEM: 'ITEM',
});

export const DiagramLayout = Object.freeze({
  GRID: 'GRID',
  TREE: 'TREE',
});

export const DiagramSlot = Object.freeze({
  GROUPS: 'GROUPS',
  CARDS: 'CARDS',
  ITEMS: 'ITEMS',
  SUBS: 'SUBS',

  /**
   * @param {DiagramSlot} scope
   * @returns {String}
   */
  toLabel(scope) {
    switch (scope) {
      case DiagramSlot.GROUPS:
        return 'Groups';
      case DiagramSlot.CARDS:
        return 'Cards';
      case DiagramSlot.ITEMS:
        return 'Items';
      case DiagramSlot.SUBS:
        return 'Sub-items';
      default:
        return null;
    }
  },
});

export const DiagramTemplate = Object.freeze({
  LINK: 'LINK',
  GROUP: 'GROUP',
  GRAPH: 'GRAPH',
  CARD: 'CARD',
  ITEMS: 'ITEMS',
  ITEM: 'ITEM',
  SUB: 'SUB',
});

export const EntitySource = Object.freeze({
  CALENDAR: 'CALENDAR',
  INTEGRATION: 'INTEGRATION',
  NONE: 'NONE',
});

export const EntityMetric = Object.freeze({
  ACTIVITIES: 'activities',
  AVERAGE_SPAN: 'averageSpan',
  BUDGET: 'budget',
  ENTITY_PERCENTAGE: 'entityPercentage',
  FTE: 'fte',
  GROUPS: 'groups',
  HOURS: 'hours',
  HOURS_PERCENTAGE: 'hoursPercentage',
  MANAGERS: 'managers',
  MOVED: 'moved',
  PERCENTAGE: 'percentage',
  RELATIVE_PERCENTAGE: 'relativePercentage',
  ROLES: 'roles',
  SPAN: 'span',
});

export const Feature = Object.freeze({
  BUDGETING: 'feature_budgeting',
  MANAGE_LIBRARY_TAGS: 'feature_library_tags_manage',
  MANAGE_LIBRARY_ROLES: 'feature_library_roles_manage',
  ORGANISATION_DESIGN: 'feature_design_organisation',
  PLAYGROUND: 'feature_playground',
  UNLIMITED_PEOPLE: 'feature_people_unlimited',
  INTEGRATIONS: 'feature_integrations',
  PULSES: 'feature_pulses',
});

export const Flows = Object.freeze({
  ROLE_ONBOARD: 'ROLE_ONBOARD',
});

export const HistoricalResolution = Object.freeze({
  NONE: 'NONE',
  HOUR: 'HOUR',
  DAY: 'DAY',
  WEEK: 'WEEK',
  MONTH: 'MONTH',
  QUARTER: 'QUARTER',
  YEAR: 'YEAR',
});

export const IntegrationType = Object.freeze({
  CHAT: 'CHAT',
  HRIS: 'HRIS',
  TICKETING: 'TICKETING',

  /**
   * @param {IntegrationType} type
   * @return {String}
   */
  label(type) {
    switch (type) {
      case this.CHAT:
        return 'Chat';
      case this.HRIS:
        return 'People';
      case this.TICKETING:
        return 'Activities';
    }
  },
});

export const Lens = Object.freeze({
  PRODUCTIVITY: 'PRODUCTIVITY',
  TAG: 'TAG',
  WORK_HEALTH: 'WORK_HEALTH',
  WORKLOAD: 'WORKLOAD',
  OUTDATED_ROLES: 'OUTDATED_ROLES',
  OVER_CAPACITY: 'OVER_CAPACITY',
  GAP: 'GAP',
  UNUTILISED: 'UNUTILISED',
});

export const LibrarySection = PRODUCTION
  ? Object.freeze({
      ACTIVITIES: 'ACTIVITIES',
      FLOW_TEMPLATES: 'FLOW_TEMPLATES',
      PROPERTIES: 'PROPERTIES',
      SKILLS: 'SKILLS',
    })
  : Object.freeze({
      ACTIVITIES: 'ACTIVITIES',
      FLOW_TEMPLATES: 'FLOW_TEMPLATES',
      PROPERTIES: 'PROPERTIES',
      ROLES: 'ROLES',
      SKILLS: 'SKILLS',
      TAGS: 'TAGS',
    });

export const NumberFormat = Object.freeze({
  CURRENCY: 'CURRENCY',
  DEFAULT: 'DEFAULT',
  FIXED: 'FIXED',
  NONE: 'NONE',
});

export const OrganisationPermission = Object.freeze({
  BUDGET: 'organisation.budget',
  MANAGE: 'organisation.manage',
  READ: 'organisation.read',
  WRITE: 'organisation.write',
});

/**
 * @todo migrate these roles
 */
export const OrganisationRole = Object.freeze({
  ADMIN: 'organisation.admin',
  MEMBER: 'organisation.editor',
});

export const Position = Object.freeze({
  BOTTOM: 'BOTTOM',
  BOTTOM_LEFT: 'BOTTOM_LEFT',
  BOTTOM_RIGHT: 'BOTTOM_RIGHT',
  LEFT: 'LEFT',
  CENTER: 'CENTER',
  RIGHT: 'RIGHT',
  TOP_LEFT: 'TOP_LEFT',
  TOP: 'TOP',
  TOP_RIGHT: 'TOP_RIGHT',
});

export const PolicyLevel = Object.freeze({
  ADMIN: 'admin',
  MEMBER: 'member',
  GUEST: 'guest',
});

export const Property = Object.freeze({
  ACTIVITIES: 'activities',
  ASSIGNEE: 'assignee',
  AVATAR: 'avatar',
  BUDGET: 'budget',
  COLLABORATORS: 'collaborators',
  DESCRIPTION: 'description',
  DISABLED: 'disabled',
  EMAIL: 'email',
  EXTERNAL_ID: 'external_id',
  FTE: 'fte',
  GROUP: 'group',
  GROUPS: 'groups',
  HOURS: 'hours',
  INSIGHTS: 'insights',
  LEAD: 'lead',
  MANAGER: 'manager',
  NAME: 'name',
  OBJECTIVE: 'objective',
  ORDER: 'order',
  OWNER: 'owner',
  PARENT: 'parent',
  PERCENTAGE: 'percentage',
  PROPERTIES: 'properties',
  ROLE: 'role',
  ROLES: 'roles',
  SALARY: 'salary',
  SKILLS: 'skills',
  SUPPORTERS: 'supporters',
  TAGS: 'tags',
  TITLE: 'title',
  UNALLOCATED: 'unallocated',
});

export const PropertyType = Object.freeze({
  DROPDOWN: 'DROPDOWN',
  TEXT: 'TEXT',
});

export const PulseDelivery = Object.freeze({
  EMAIL: 'EMAIL',
  SLACK: 'SLACK',
});

export const Relationship = Object.freeze({
  OWNER: 'owner',
  MEMBER: 'member',
  MANAGER: 'manager',
  SUPPORTER: 'supporter',
  ASSIGNEE: 'assignee',
  COLLABORATOR: 'collaborator',
  VIEWER: 'viewer',
  SELF: 'self',
  NONE: 'none',
});

export const RoleType = Object.freeze({
  DEFAULT: 'DEFAULT',
  MANAGER: 'MANAGER',
  UNASSIGNED: 'UNASSIGNED',
});

export const RouteLevel = Object.freeze({
  [DesignLevel.GROUP]: 'g',
  [DesignLevel.MANAGER]: 'm',
  [DesignLevel.PERSON]: 'p',
  [DesignLevel.ROLE]: 'r',
});

export const SkillLevel = Object.freeze({
  BASIC: 'BASIC',
  INTERMEDIATE: 'INTERMEDIATE',
  ADVANCED: 'ADVANCED',

  /**
   * @param {SkillLevel} a
   * @param {SkillLevel} b
   * @return {SkillLevel}
   */
  keepHighest(a, b) {
    const levels = new Set([a, b]);
    switch (true) {
      case levels.has(this.ADVANCED):
        return this.ADVANCED;
      case levels.has(this.INTERMEDIATE):
        return this.INTERMEDIATE;
      default:
        return this.BASIC;
    }
  },
});

export const SortOrder = Object.freeze({
  ASC: 'ASC',
  DESC: 'DESC',
});

export const TagColor = Object.freeze({
  BLUE_REGULAR: 'blue_regular',
  BLUE_LIGHT: 'blue_light',
  CYAN_REGULAR: 'cyan_regular',
  CYAN_LIGHT: 'cyan_light',
  GREEN_REGULAR: 'green_regular',
  GREEN_LIGHT: 'green_light',
  GREY_REGULAR: 'grey_regular',
  GREY_LIGHT: 'grey_light',
  LILAC_REGULAR: 'lilac_regular',
  LILAC_LIGHT: 'lilac_light',
  ORANGE_REGULAR: 'orange_regular',
  ORANGE_LIGHT: 'orange_light',
  PINK_REGULAR: 'pink_regular',
  PINK_LIGHT: 'pink_light',
  PURPLE_REGULAR: 'purple_regular',
  PURPLE_LIGHT: 'purple_light',
  RED_REGULAR: 'red_regular',
  RED_LIGHT: 'red_light',
  YELLOW_REGULAR: 'yellow_regular',
  YELLOW_LIGHT: 'yellow_light',
});

export const TaggableType = Object.freeze({
  GROUP: 'GROUP',
  ROLE: 'ROLE',
  ACTIVITY: 'ACTIVITY',
});

export const InviteTarget = Object.freeze({
  DESIGN: 'DESIGN',
  FLOW: 'FLOW',
});

export const Visibility = Object.freeze({
  NONE: 'NONE',
  FULL: 'FULL',
});

/**
 * Compound
 */

export const AssociativeProperties = Object.freeze(
  new Set([Property.PROPERTIES])
);
