import openai from '../client';
import { toolsConfig } from '../tools/config';
import createFilters from '../tools/createFilters/createFilters';
import createMutation from '../tools/createMutation/createMutation';
import generateReport from '../tools/generateReport/generateReport';
import navigateTo from '../tools/navigateTo/navigateTo';
import lastMessagesSlice from './slices/lastMessagesSlice';
import orgSlice from './slices/orgSlice';
import systemSlice from './slices/systemSlice';

/*
  - add aggregate stats 
  - show filtered entities only
  - include current filters. tell it warn that there are filters on
  - data lookups based off intent

  // @todo - add skills and properties
  // @todo - optionally include data SLICES when intention is met
*/

const mainPrompt = async ({
  userText,
  lastMessages,
  snapshot,
  intentSnapshot,
  tags,
  stream,
}) => {
  console.log('INTENTzzzzzzzz CALLING MAIN PROMPT', { intentSnapshot });
  let prompt = `
    ${systemSlice()}

    Available Tools and Their Rules:

    ${Object.entries(toolsConfig).reduce((acc, [key, config], index) => {
      return (
        acc +
        `
    ${index + 1}. ${config.title} (${config.category}):
    ${config.description}
    ${config.prompt}
    `
      );
    }, '')}

    If someone ask what you can do, list out all the tools, keep it brief unless they ask for a more verbose answer.

    General Rules:
      - When looking at metrics, total means all metrics, and visible means metrics calculated after filters are applied.
      - Generally you should answer using visible metrics, unless the user specifically requests total metrics.
      - __visibility means if it is being filtered
      - For dates, use the current epoch.


`;

  if (stream) {
    prompt += `
    ${orgSlice({ snapshot, tags, intentSnapshot })}
    `;
  } else {
    prompt += `Please choose the appropriate tool to call. If the users request is not relevant to a tool, do not invoke a tool call. Instead, reply shortly with "I don't understand the question, can you rephrase it?"`;
  }

  prompt += `
    ${lastMessagesSlice({ lastMessages })}
  
    Users request: ${userText}
  `;

  const mainPrompt = {
    model: openai('gpt-4o-mini'),
    tools: {
      createMutation,
      generateReport,
      createFilters,
      navigateTo,
    },
    prompt,
  };

  return mainPrompt;
};

export default mainPrompt;
